.CompanyDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
}

.CompanyDetails .logo {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    object-fit: cover;
    margin-bottom: 20px;

}

.CompanyDetails .details {

}

.CompanyDetails .company-name {
    font-size: x-large;
    font-weight: 600;
}

.CompanyDetails .subtitle {
    font-size: xx-large;
    font-weight: 600;
}

.CompanyDetails .contact-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 40px;
    align-items: center;

}
