.JobPhotos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    position: relative;
}

.JobPhotos .title {
    font-size: large;
    border-bottom: solid 1px lightgray;

}

.JobPhotos .subtitle {
    font-size: small;
    padding-top: 5px;
    padding-bottom: 40px;
}

.JobPhotos .button-section {
    display: flex;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


.JobPhotos .loading {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


.JobPhotos .images{
    overflow: scroll;
   max-height: 300px;
    align-items: center;
    display: flex;
    flex-direction: column;

}

.JobPhotos .images .image{
    max-width: 95%;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;    margin-bottom: 15px;

}



.JobPhotos .image-master {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.JobPhotos .image-master .icon {
    position: absolute;
    top: 3px;
    right: 15px;
}
