.Urgency {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;
    height: fit-content;
}

.Urgency .title {
    font-size: large;
    border-bottom: solid 1px lightgray;
    padding-bottom: 5px;
    margin-bottom: 5px;

}

.Urgency .subtitle {
    font-size: small;
    padding-bottom: 20px;

}

.Urgency .slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.Urgency .helper {
    width: 100px;
    font-size: small;

}
