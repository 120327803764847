.Home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow:hidden;
    position: relative;

}

.Home .loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 5;
    position: absolute;

}

.Home .card {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;
    max-height: 700px;
    overflow: hidden;
}

.Home .nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}