.Contact {
    padding: 10px;

} 
.Contact .title {
    font-size: large;
    border-bottom: solid 1px lightgray;
    margin-top: 10px;

}

.Contact .subtitle {
    font-size: small;
    margin-bottom: 20px;
}

.Contact  .fields {

}

.Contact  .field {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.Contact  .address-field {
    width: 100%;
    position: relative;
    margin-top: 5px;

}

.Contact  .label {
    position: absolute;
    font-size: small;
    background-color: white;
    z-index: 5;
    left: 10px;
    top: -10px;
    padding-left: 2px;
    padding-right: 2px;
    color: gray;

}

