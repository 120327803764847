.Confirm {
    padding: 10px;
    overflow: scroll;
    height: calc(100% - 20px);
}

.Confirm::-webkit-scrollbar {
    display: none;
}

.Confirm .title {
    font-size: large;
    border-bottom: solid 1px lightgray;
    margin-bottom: 5px;

}

.Confirm .subtitle {
    font-size: small;

}


.Confirm .subheading {
    font-size: medium;
    border-bottom: solid 1px lightgray;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;

}


.Confirm  .confirmation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.Confirm  .message {
    font-size: 11pt;

}



.Confirm .images{
    align-items: center;
    display: flex;
    flex-direction: column;


}

.Confirm .images .image{
    max-width: 95%;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;    margin-bottom: 15px;

}



.Confirm .slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.Confirm .helper {
    width: 100px;
    font-size: small;

}



.Confirm  .fields {

}

.Confirm  .field {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.Confirm  .address-field {
    width: 100%;
    position: relative;
    margin-top: 5px;

}

.Confirm  .label {
    position: absolute;
    font-size: small;
    background-color: white;
    z-index: 5;
    left: 10px;
    top: -10px;
    padding-left: 2px;
    padding-right: 2px;
    color: gray;

}


